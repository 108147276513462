/* 
reactstrap modal z-index is at 1050, 
so spinner and overlay must be above 1050 to remain on top 
*/

.spinner {
  width: 50px;
  height: 50px;
}

.loadingPanel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1052;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background-color: rgba(50,34,34,0.5); /* "Street Tire" color from Sparc login page image */
}